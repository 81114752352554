.exp-main-container{
    width: 80%;
    height: 50vw;
    display: flex;
    margin-left : 10%;
    margin-right : 10%;
    margin-top: 5%;
    flex-direction: column;
}
.exp-head{
    height: 10vw;
    width: 100%;
    text-align: left;
    font-size: 5vw;
    font-family: Oswald;
    color: #8b86be;
    font-weight: bold;

}
.exp-timeline-container{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.exp-timeline-upper{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: solid 0.1vw #8b86be;

}

.exp-timeline-below{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: solid 0.1vw #8b86be;

}

.exp-card-container{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.exp-card{
    width: 80%;
    height: 100%;

}
.exp-card-connector{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.exp-connector-1{
    height: 50%;
}
.exp-connector-2{
    height: 50%;
}

.exp-card-container.lower{
    flex-direction: row-reverse;
}

.exp-card-container.lower .exp-connector-1 {
    border-right: 0.2vw solid #8b86be;
    border-bottom: 0.2vw solid #8b86be;
    border-bottom-right-radius: 50%;
}

.exp-card-container.upper .exp-connector-2 {
    border-left: 0.2vw solid #8b86be;
    border-top: 0.2vw solid #8b86be ;
    border-top-left-radius: 50%;
}
.exp-card-container.upper{ 
    margin-left: 10%;
}

.exp-card-container.lower{ 
    margin-left: 10%;
}

.exp-card-container.upper .exp-blob{
    height: 2vw;
    width: 2vw;
    background-color: #8b86be;
    position: relative;
    bottom: calc(-100% + 1vw);
    left: -1.2vw;
    border-radius: 100%;
}

.exp-card-container.lower .exp-blob{
    height: 2vw;
    width: 2vw;
    background-color: #8b86be;
    position: relative;
    top: -1.2vw;
    right: calc(-100% + 1vw);
    border-radius: 100%;
}

.exp-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.exp-card-content{
    height: 70%;
    width: 100%;
    background: black;
    border-radius: 2vw;
    display: flex;
    box-shadow: 0 25px 50px rgba(0,0,0,0.55);
    flex-direction: column;
    justify-content: space-around;
}

.exp-card-title{
    /* height: 30%; */
    font-size: 1.8vw;
    color: #8b86be;
    text-align: center;
    font-weight: bolder;
    font-family: OutFit;
}
.exp-card-time{
    height: 10%;
    color: #8b86be;
    text-align: center;
    font-size: 1.2vw;
    color: #8b86be;
    text-align: right;
    padding-right: 5%;
    font-family: OutFit;

}

.exp-card-desc{
    height: 40%;
    color: #8b86be;
    text-align: center;
    padding: 1%;
    font-size: 1.2vw;
    color: white;
    text-align: center;
    font-family: OutFit;

}
.section-container{
    width: 100%;
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: #14142B; */
    /* background-color: #1F1D1D; */
    background-color: #151414;
}

/* #project-section{
    background-color: white;
} */

.body-wrapper{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
}

@media only screen and (max-width: 1000px) {
    .section-container{
        height: 100vh;
    }
  }
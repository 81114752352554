@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.navbar-container{
    width: 90%;
    margin-top: 1%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 0.5%;
    position:fixed;
    top : 0;
    height: 2.5vw;
    font-family: "Bebas Neue", sans-serif;
    font-weight: bolder;
    z-index: 10000000;
}

.navbar-option{
    height: 1.5vw;
    width: 9%;
    text-align: center;
    font-size: 1.5vw;
    padding: 0.5%;
    /* border-radius: 0.8vw; */
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .navbar-container{
        height: 8vw;
    }
    .navbar-option{
        height: 5vw;
        font-size: 4vw;
        width: 20%;
        padding: 1%;
    }
   }
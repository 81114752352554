@import url('https://fonts.googleapis.com/css?family=Oswald');

.stories-container{
    width: 80%;
    height: 40vw;
    display: flex;
    margin-left : 10%;
    margin-right : 10%;
    margin-top: 5%;
    flex-direction: column;
}

.stories-head{
    width: 100%;
    height: 10vw;
    color:rgb(165,255,214, 1.0);
    text-align: left;
    font-size: 5vw;
    font-family: Oswald;
    font-weight: bold;
}
.story-carousel-container{
    width: 100%;
    height: 30vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.story-container{
    width: 15%;
    margin: 1%;
    height: 28.5vw;
    background-color: black;
    /* transition: width 0.5s ease-in-out; */
    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    transition: width 0.5s ease-in-out;
    /* display: flex;
    flex-direction: column; */
    overflow: hidden;
    position: relative;
}

.story-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

}

.story-container:hover .story-head{ 
    color: rgb(165,255,214, 1.0);
    z-index: 1;
}

.story-container:hover{
    width: 40%;
}

.story-container:hover .story-image{
    opacity: 0.75;
}

.story-head{
    height: 5vw;
    color: rgba(0, 0, 0, 0.0);
    text-align: left;
    padding-left: 5%;
    transition: color 0.5s ease-in-out;
    font-size: 3vw;
    font-weight: 800;
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 0;
    right: 0;
    bottom: 0;
}

@media only screen and (max-width: 1000px) {
    .story-carousel-container{
        /* flex-shrink: 0; */
        width: 100%;
        overflow-x: auto;
        justify-content:flex-start;
        height: 80vw;
        overflow-y: hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        margin-top: 10%;
    }
    .story-carousel-container::-webkit-scrollbar {
        display: none;
      }
    .stories-container{
        /* flex-shrink: 0; */
        height: 100vw;
    }
    .story-container:hover,
    .story-container{
        height: 40vw;
        flex-shrink: 0;
        width: 80%;
        z-index: 5;
        border-radius: 3vw;
        transition: transform .8s ease-in;
        margin: 10%;
        
    }
    .stories-container .story-head{
        color: rgb(165,255,214, 1.0);
        height: 10vw;
        font-size: 8vw;
        text-align: center;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .story-container.not-focused-right{
        transform: translatex(-40%) scale(.8);
        z-index: 0;
        opacity: 0.4;

    }
    .story-container.not-focused-left{
        transform: translatex(40%) scale(.8);
        z-index: 0;
        opacity: 0.4;

    }
    .stories-head{
        height: 16vw;
        font-size: 15vw;
        text-align: center;
    }

}
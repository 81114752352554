.exp-main-container{
    height: 120vw;
    width: 80%;
}
.exp-head-mob{
    height: 18vw;
    font-size: 15vw;
    text-align: center;
    font-family: Oswald;
    font-weight: bold;
    color: #8b86be;
    margin-bottom: 10vw;
}
.exp-container{
    height: calc(120vw - 20vw);
    width: 100%;
}
.mobile-timeline{
    height: 8vw;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    position: relative;
    z-index: 1;

}
.mobile-blobs-container{

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    z-index: 100;

}
.mobile-timeline-div{
    z-index: 2;
    position: absolute;
    top: 0;
	left: 0;
    height: 100%;
    width: 100%;
}

.timeline-div-2{
    height: 50%;
    width: 100%;
    border-top: solid 0.7vw #5c5b59;
}
.timeline-div-1{
    height: 50%;
    width: 100%;
    border-bottom: solid 0.7vw #5c5b59;
}
.mobile-timeline-blob{
    width: 8vw;
    height: 8vw;
    background-color: #5c5b59;
    border-radius: 100%;
}


.exp-carousel{
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    height: 70vw;
    margin-top: 2vw;
    
    overflow-x:auto;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;


}
.exp-times{
    height: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}
.exp-time{
    height: 5vw;
    width: 7vw;
    color: white;
    font-size: 2.5vw;
    padding: 2vw;
}

.exp-mobile-card-container{
    width: 80%;
    flex-shrink: 0;
    /* background-color: #8b86be; */
    margin-right: 10%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 5%;
    margin-right: 5%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(12.3px);
    -webkit-backdrop-filter: blur(12.3px); 
    padding: 5%; 
}
.exp-mobile-title{
    height: 30%;
    width: 100%;
    font-family: OutFit;
    font-size: 5vw;
    color: #8b86be;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    
}
.exp-mobile-time{
    height: 20%;
    width: 100%;
    font-size: 4vw;
    color: white;
    text-align: center;
}
.exp-mobile-desc{
    height: 40%;
    width: 100%;
    font-size: 4vw;
    color: white;
    text-align: center;
}
.exp-safari-placeholder{
    width: 3%;
    flex-shrink: 0;

    height: 100%;
}
#time-blob-active{
    background-color: #8b86be;
}